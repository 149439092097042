import { configPrepare } from "@/helpers/vue-good-table"
import { i18n } from "@/config"

const defaults = {
  tdClass: "organizations-table-td",
  thClass: "organizations-table-th",
  sortable: false
}

const i18nPrefix = "organizations.table.columns"

export const columns = () =>
  configPrepare(
    [
      {
        field: "id",
        tdClass: "organizations-table-td id-field",
        sortable: true
      },
      {
        field: "name",
        sortable: true
      },
      "schema_name",
      {
        field: "ota",
        sortable: false,
        thClass: "organizations-table-th expandable-field",
        tdClass: "organizations-table-td expandable-field"
      },
      "max_active_shops_count",
      "max_competitors_count",
      {
        field: "price_management_mode",
        formatFn: mode => i18n.t(`organization_management.modes.${mode}`)
      },
      {
        field: "shared_inventory_enabled",
        formatFn: status => i18n.t(`organization_management.shared_inventory_enabled.${status}`),
        sortable: true
      },
      {
        field: "active",
        formatFn: active => String(!!active),
        tdClass: "organizations-table-td active-field",
        sortable: true
      },
      {
        label: "",
        field: "actions",
        tdClass: "accounts-table-td text-right actions-field",
        sortable: false
      }
    ],
    {
      i18nPrefix,
      defaults
    }
  )
