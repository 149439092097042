import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import actionsBuild from "./actions"

const BASE_URI = "/maestro/car_classes"

const store = new StoreItemsModule({ baseURI: BASE_URI })

const { FETCH_ITEMS } = actionsBuild({ baseURI: BASE_URI })
store.mergeActions({ FETCH_ITEMS }, withLoading)

export default store
