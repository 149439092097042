import { api } from "@/config"

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit }, options = {}) => {
    const { headers, ...params } = options

    const response = await api.get(baseURI, { params, headers })

    commit("SET_ITEMS", response.data.data)
  }
})
